<template>
  <div>
    <b-card title="Top Products">
      <b-row class="ml-auto">
        <!-- sorting -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Sort By"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- filter -->
        <b-col md="4" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchData">
                  Search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="1" class="my-1">
          <!-- print and save pdf & excel -->
          <div class="ml-auto mr-2">
            <b-dropdown
              style="height: 2.142rem; line-height: 0.5"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              right
              variant="primary"
              size="sm"
              class="dropdown-icon-wrapper"
            >
              <template #button-content
                ><feather-icon
                  icon="PrinterIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <download-excel :data="exportItems">
                <b-dropdown-item>
                  <feather-icon icon="DownloadIcon" /> Download
                  Excel</b-dropdown-item
                >
              </download-excel>
              <b-dropdown-divider />
              <b-dropdown-item @click="genPDF">
                <feather-icon icon="DownloadIcon" /> Save PDF</b-dropdown-item
              >
              <b-dropdown-divider />
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <b-table
        id="AdminsTable"
        ref="admint"
        striped
        hover
        responsive
        show-empty
        :busy.sync="isBusy"
        class="position-relative"
        :items="items"
        :fields="fields"
      >
        <template #cell(is_new)="data">
          <b-badge variant="success" v-if="data.value"> New </b-badge>
          <b-badge variant="warning" v-else> Used </b-badge>
        </template>
        <!-- user actions -->
        <template #cell(actions)="data">
          <b-button
            style="padding: 7px; margin: 6px; width: 35px; height: 35px"
            class="btn-icon rounded-circle"
            :to="{
              path:
                (data.item.is_new ? 'New' : 'Used') +
                '/ShowProduct/variant/show/' +
                data.item.id,
            }"
            variant="success"
          >
            <feather-icon style="width: 18px; height: 18px" icon="EyeIcon" />
          </b-button>
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            @change="fetchData"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
        <!-- pagination -->
        <div>
          <b-pagination
            @input="fetchData()"
            aria-controls="AdminsTable"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </div>
      </b-card-body>
    </b-card>
    <!-- handle error -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      authPermissions: [],
      showDismissibleAlert: false,
      errors_back: [],
      errors_delete: {},
      perPage: 25,
      pageOptions: [25, 50, 100],
      totalRows: 1,
      isBusy: false,
      currentPage: 1,
      sortBy: 'visitors',
      sortDesc: true,
      sortDirection: 'desc',
      filter: '',
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          label: 'Id',
          key: 'id',
          class: 'th-spaces-update',
          sortable: true,
        },
        {
          label: 'SKU',
          key: 'sku',
          class: 'th-spaces-update',
        },
        {
          label: 'Vendor Store Name',
          key: 'vendor_storename',
          class: 'th-spaces-update',
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'New/Used',
          key: 'is_new',
          class: 'th-spaces-update',
        },
        {
          label: 'Name',
          key: 'product_name',
          class: 'th-spaces-update',
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'visitors',
          key: 'visitors',
          class: 'th-spaces-update',
          sortable: true,
        },
        {
          label: 'view cost',
          key: 'visitors_cost',
          class: 'th-spaces-update',
        },
        {
          label: 'Number Added To Carts',
          key: 'cart_variants_number',
          class: 'th-spaces-update',
          sortable: true,
        },
        {
          label: 'Quantity',
          key: 'quantity',
          class: 'th-spaces-update',
        },
        {
          label: 'Vendor price',
          key: 'original_price',
          class: 'th-spaces-update',
        },
        {
          label: 'sale price',
          key: 'sale_price',
          class: 'th-spaces-update',
        },
        {
          label: 'Discount',
          key: 'offer_amount',
          class: 'th-spaces-update',
        },
        {
          label: 'profit',
          key: 'profit',
          class: 'th-spaces-update',
        },
        {
          label: 'Created at',
          key: 'created_date',
          class: 'th-spaces-update',
        },
        {
          label: 'Action',
          key: 'actions',
          class: 'th-spaces-update',
        },
      ],

      items: [],
      exportItems: [],
    }
  },
  created() {
    this.fetchData()
    this.authPermissions = this.$store.state.authPermissions
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }))
    },
    rows() {
      return this.totalRows
    },
  },
  watch: {
    sortDesc: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    sortBy: function () {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },
    filter: function () {
      if (this.filter == '' || this.filter == null) {
        this.fetchData()
      }
    },
  },
  methods: {
    // fetch data of users
    fetchData() {
      // get users Data
      var url = null
      if (this.filter == '') {
        url =
          'variants/top-products/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '?page=' +
          this.currentPage
      } else {
        url =
          'variants/top-products/' +
          this.perPage.toString() +
          '/sortBy-' +
          this.sortBy +
          '-' +
          this.sortDirection +
          '/' +
          this.filter +
          '?page=' +
          this.currentPage
      }
      const promise = axios.get(url)
      return promise
        .then((result) => {
          this.items.length = 0
          this.totalRows = result.data.data.total
          this.items = result.data.data.data

          this.exportItems = this.items

          this.$refs.admint.refresh()
          return this.items
        })
        .catch((err) => {
          console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },

    // fetch data of search result
    // fetch data of sort result
    searchData() {
      this.currentPage = 1
      if (this.sortDesc == true) {
        this.sortDirection = 'desc'
      } else {
        this.sortDirection = 'asc'
      }
      this.fetchData()
    },

    genPDF() {
      const { jsPDF } = require('jspdf')
      const doc = new jsPDF()
      let print_body = []
      for (let index = 0; index < this.items.length; index++) {
        print_body[index] = [
          this.items[index].id,
          this.items[index].name,
          this.items[index].email,
          this.items[index].created_at,
          this.items[index].updated_at,
        ]
      }
      doc.autoTable({
        head: [['ID', 'NAME', 'Email', 'CREATED_AT', 'UPDATED_AT']],
        body: print_body,
      })
      doc.save('table.pdf')
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
  
  <style lang="css" >
.b-table-sticky-header {
  max-height: 92vh;
}
.th-spaces-update {
  white-space: nowrap;
  text-align: center;
  direction: ltr;
}
</style>
  
  
  